import React, { useState, useCallback } from "react"
import { navigate } from "gatsby"
import { localStorageWrapper } from "./storage"
const HUT_FOUND_STORAGE_KEY = "FOUND_HUTS"

type FoundHut = string

let storageFoundHuts: FoundHut[]
let foundHutsData = localStorageWrapper.getItem(HUT_FOUND_STORAGE_KEY)
if (foundHutsData) {
  storageFoundHuts = JSON.parse(foundHutsData)
} else {
  storageFoundHuts = []
}

const GEOLOCATION_OK_STORAGE_KEY = "GEO_OK"
let isGeolocationOk = !!localStorageWrapper.getItem(GEOLOCATION_OK_STORAGE_KEY)

export type GeolocationContextType = {
  isActive: boolean
  activate: any
  results: any
  foundHuts: FoundHut[]
  addFoundHut: (hutId: string) => void
}

export const GeolocationContext = React.createContext<GeolocationContextType>({
  isActive: false,
  activate: null,
  results: [],
  foundHuts: [],
  addFoundHut: null,
})

function AppState({ children }) {
  const [haveAskedAboutGeolocation, setHaveAskedAboutGeolocation] = useState<
    boolean
  >(false)
  const [isGeolocationActive, setIsGeolocationActive] = useState(
    isGeolocationOk
  )
  const [geolocationResults, setGeolocationResults] = useState([])
  const [foundHuts, setFoundHuts] = useState<FoundHut[]>(storageFoundHuts)

  const addFoundHut = (hutId: string) => {
    setFoundHuts(prev => {
      const foundHuts = [...prev, hutId]
      localStorageWrapper.setItem(
        HUT_FOUND_STORAGE_KEY,
        JSON.stringify(foundHuts)
      )
      return foundHuts
    })
  }

  const pollLocation = useCallback(() => {
    if (haveAskedAboutGeolocation) {
      console.info("No longer asking about geolocation")
      return
    }
    navigator.geolocation.getCurrentPosition(
      success,
      error => {
        console.error("Geolocation error:", error)
      },
      {}
    )
    setHaveAskedAboutGeolocation(true)
  }, [haveAskedAboutGeolocation, setHaveAskedAboutGeolocation])

  const success = useCallback(result => {
    const latitude = result.coords.latitude
    const longitude = result.coords.longitude
    setGeolocationResults([latitude, longitude])
  }, [])

  const activateGeolocation = () => {
    localStorageWrapper.setItem(GEOLOCATION_OK_STORAGE_KEY, true.toString())
    setIsGeolocationActive(true)
    pollLocation()
    navigate("/map")
  }

  return (
    <GeolocationContext.Provider
      value={{
        isActive: isGeolocationActive,
        activate: activateGeolocation,
        results: geolocationResults,
        foundHuts,
        addFoundHut,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  )
}

export default AppState

export const HutOverlayPolygon = [
  //rect
  [
    [169.310384615384701, -43.664022689013031],
    [169.300384615384701, -37.024675568498836],
    [179.877115384615507, -36.995652120727968],
    [179.913461538461632, -43.69030976870193],
    [169.310384615384701, -43.664022689013031],
  ],
  //paeka
  [
    [174.943534637820392, -40.989695060872705],
    [174.972928150848048, -40.989489511830556],
    [174.972928150848048, -40.970373450910479],
    [174.958539717897452, -40.963179234435181],
    [174.943740186862527, -40.970990098036935],
    [174.943534637820392, -40.989695060872705],
  ],
  //whareroa
  [
    [174.973992562565968, -40.979132977677843],
    [174.999179565318485, -40.979077377230261],
    [174.999123964870904, -40.950220744937667],
    [174.997733953681461, -40.950276345385248],
    [174.992229509371327, -40.967123281001157],
    [174.992118308476165, -40.957448803122709],
    [174.973936962118415, -40.95750440357029],
    [174.973992562565968, -40.979132977677843],
  ],
  //kaitawa
  [
    [175.009333369296343, -40.910653262273009],
    [175.006972952381489, -40.912729291607739],
    [175.005806963303087, -40.913497138074014],
    [175.003076842534114, -40.914862198458493],
    [175.000176089217092, -40.915743799956807],
    [174.998498202494517, -40.915999748778894],
    [174.997332213416087, -40.919327083466072],
    [174.997047825835978, -40.920578388818512],
    [174.997161580868038, -40.923479142135541],
    [174.997616600996196, -40.924702008729973],
    [174.99832756994644, -40.925867997808389],
    [174.999351365234816, -40.927062425644806],
    [175.00074486437731, -40.928057782175159],
    [175.002422751099886, -40.92888250615745],
    [175.004271270370538, -40.929422842559646],
    [175.006432615979321, -40.929792546413772],
    [175.010214970794635, -40.929934740203826],
    [175.013741376787891, -40.929593475107701],
    [175.016670568862935, -40.928825628641434],
    [175.017978751731391, -40.928199975965214],
    [175.019969464792098, -40.926721160548688],
    [175.02133452517657, -40.924758886245996],
    [175.02181798406275, -40.923479142135541],
    [175.022073932884837, -40.92120404149474],
    [175.021931739094782, -40.920094929932347],
    [175.020680433742342, -40.916170381326957],
    [175.017637486635266, -40.915516289892729],
    [175.015703651090575, -40.914862198458493],
    [175.013741376787891, -40.91398059696018],
    [175.011409398631059, -40.912501781543661],
    [175.009333369296343, -40.910653262273009],
    [175.009333369296343, -40.910653262273009],
  ],
  //waikanae
  [
    [175.063367224215085, -40.884112938747286],
    [175.087245911110244, -40.884160791827235],
    [175.087389470350104, -40.873250289598573],
    [175.082556309275134, -40.867938597724098],
    [175.070880157767277, -40.86789074464415],
    [175.068296091449952, -40.870761929441166],
    [175.065999143612345, -40.862483346609771],
    [175.063271518055188, -40.862483346609771],
    [175.063367224215085, -40.884112938747286],
  ],
  //otaki
  [
    [175.10526709573233, -40.736012351816441],
    [175.105227100643447, -40.736052346905318],
    [175.105147110465708, -40.736652273238398],
    [175.105347085910068, -40.737252199571486],
    [175.105827026976556, -40.737852125904567],
    [175.106426953309636, -40.738372062059909],
    [175.107066874731572, -40.738732017859761],
    [175.10774679124242, -40.739091973659612],
    [175.108586688108744, -40.739251954015103],
    [175.131583864210342, -40.739211958926226],
    [175.131823834743557, -40.739211958926226],
    [175.132543746343259, -40.739051978570743],
    [175.133263657942962, -40.738692022770891],
    [175.133823589187188, -40.738372062059909],
    [175.134223540075908, -40.738012106260058],
    [175.134623490964628, -40.737652150460207],
    [175.134943451675611, -40.737052224127126],
    [175.134943451675611, -40.736052346905318],
    [175.120065278615101, -40.718934448867955],
    [175.10526709573233, -40.736012351816441],
  ],
]

const Hut1 = {
  description: "Paekākāriki hut",
  path: "/huts/hut-1",
  colour: "#D25511",
  verticalOffset: 0.5,
  startCoords: [174.96355, -40.9771],
  coords: [
    [174.943534637820392, -40.989695060872705],
    [174.943740186862527, -40.970990098036935],
    [174.958539717897452, -40.963179234435181],
    [174.972928150848048, -40.970373450910479],
    [174.972928150848048, -40.989489511830556],
    [174.943534637820392, -40.989695060872705],
  ],
}

const Hut2 = {
  description: "Whareroa hut",
  path: "/huts/hut-2",
  colour: "#225287",
  verticalOffset: -0.5,
  startCoords: [174.9834356, -40.9723568],
  coords: [
    [174.973992562565968, -40.979132977677843],
    [174.973936962118415, -40.95750440357029],
    [174.992118308476165, -40.957448803122709],
    [174.992229509371327, -40.967123281001157],
    [174.997733953681461, -40.950276345385248],
    [174.999123964870904, -40.950220744937667],
    [174.999179565318485, -40.979077377230261],
    [174.973992562565968, -40.979132977677843],
  ],
}

const Hut3 = {
  description: "Kaitawa hut",
  path: "/huts/hut-3",
  colour: "#2C8B27",
  verticalOffset: 0,
  startCoords: [175.008766799999989, -40.9219766],
  coords: [
    [175.009333369296343, -40.910653262273009],
    [175.010243409552658, -40.911534863771315],
    [175.011409398631059, -40.912501781543661],
    [175.012490071435451, -40.913269628009928],
    [175.013741376787891, -40.91398059696018],
    [175.015703651090575, -40.914862198458493],
    [175.017637486635266, -40.915516289892729],
    [175.01931537335787, -40.915857554988847],
    [175.020680433742342, -40.916170381326957],
    [175.021448280208631, -40.918388604451742],
    [175.021931739094782, -40.920094929932347],
    [175.022073932884837, -40.92120404149474],
    [175.022017055368821, -40.922370030573148],
    [175.02181798406275, -40.923479142135541],
    [175.02133452517657, -40.924758886245996],
    [175.020680433742342, -40.925811120292366],
    [175.019969464792098, -40.926721160548688],
    [175.019002547019767, -40.92751744577297],
    [175.017978751731391, -40.928199975965214],
    [175.016670568862935, -40.928825628641434],
    [175.01527706972044, -40.929280648769591],
    [175.013741376787891, -40.929593475107701],
    [175.011921296275261, -40.929849423929795],
    [175.010214970794635, -40.929934740203826],
    [175.00805362518588, -40.929877862687803],
    [175.006432615979321, -40.929792546413772],
    [175.004271270370538, -40.929422842559646],
    [175.002422751099886, -40.92888250615745],
    [175.00074486437731, -40.928057782175159],
    [174.999351365234816, -40.927062425644806],
    [174.99832756994644, -40.925867997808389],
    [174.997616600996196, -40.924702008729973],
    [174.997161580868038, -40.923479142135541],
    [174.997019387077984, -40.921943449202999],
    [174.997047825835978, -40.920578388818512],
    [174.997332213416087, -40.919327083466072],
    [174.997900988576305, -40.917706074259499],
    [174.998498202494517, -40.915999748778894],
    [175.000176089217092, -40.915743799956807],
    [175.001455833327555, -40.915402534860682],
    [175.003076842534114, -40.914862198458493],
    [175.004271270370538, -40.914264984540281],
    [175.005806963303087, -40.913497138074014],
    [175.006972952381489, -40.912729291607739],
    [175.007996747669864, -40.911933006383457],
    [175.007996747669864, -40.911933006383457],
    [175.009333369296343, -40.910653262273009],
  ],
}

const Hut4 = {
  description: "Ōtaki hut",
  path: "/huts/hut-4",
  colour: "#FDC30A",
  verticalOffset: 0,
  startCoords: [175.1196629, -40.7325496],
  coords: [
    [175.10526709573233, -40.736012351816441],
    [175.120065278615101, -40.718934448867955],
    [175.134943451675611, -40.736052346905318],
    [175.134943451675611, -40.737052224127126],
    [175.134623490964628, -40.737652150460207],
    [175.134223540075908, -40.738012106260058],
    [175.133823589187188, -40.738372062059909],
    [175.133263657942962, -40.738692022770891],
    [175.132543746343259, -40.739051978570743],
    [175.131823834743557, -40.739211958926226],
    [175.131583864210342, -40.739211958926226],
    [175.108586688108744, -40.739251954015103],
    [175.10774679124242, -40.739091973659612],
    [175.107066874731572, -40.738732017859761],
    [175.106426953309636, -40.738372062059909],
    [175.105827026976556, -40.737852125904567],
    [175.105347085910068, -40.737252199571486],
    [175.105147110465708, -40.736652273238398],
    [175.105227100643447, -40.736052346905318],
    [175.10526709573233, -40.736012351816441],
  ],
}

const Hut5 = {
  description: "Waikanae hut",
  path: "/huts/hut-5",
  colour: "#117F7D",
  verticalOffset: 0,
  startCoords: [175.075855600000011, -40.8754995],
  coords: [
    [175.063367224215085, -40.884112938747286],
    [175.063271518055188, -40.862483346609771],
    [175.065999143612345, -40.862483346609771],
    [175.068296091449952, -40.870761929441166],
    [175.070880157767277, -40.86789074464415],
    [175.082556309275134, -40.867938597724098],
    [175.087389470350104, -40.873250289598573],
    [175.087245911110244, -40.884160791827235],
    [175.063367224215085, -40.884112938747286],
  ],
}

export const Huts = [Hut1, Hut2, Hut3, Hut4, Hut5]
