import React from "react"

type HutInfoSectionProps = {
  heading: string
  headingImg: string
  headingColour: string
  description: string
}

const HutInfoSection = ({
  heading,
  headingImg,
  headingColour,
  description,
}: HutInfoSectionProps) => (
  <div className="hut-info u-side-pad u-text-center">
    <h1 className="u-accessible-text">{heading}</h1>
    <img src={headingImg} role="presentation" className="hut-info__title-img" />
    <p className="hut-info__description" style={{ color: headingColour }}>
      {description}
    </p>
  </div>
)

export default HutInfoSection
