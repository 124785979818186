import React from "react"
import classnames from "classnames"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
// import imgDirections from "./images/accordion-directions.jpg"
// import imgHutFiction from "./images/accordion-hutfiction.jpg"
// import imgLogbook from "./images/accordion-logbook.jpg"

export type AccordionContent = {
  title: string
  inner?: React.ReactNode | React.ReactNode[]
  locked?: boolean
  backgroundColour?: string
  // backgroundImage?: string
}

export type AccordionProps = {
  contents: AccordionContent[]
  unlock?: React.ReactNode
  noSpace?: boolean
}

const Accordions = ({ contents, unlock, noSpace }: AccordionProps) => {
  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      className={classnames("accordion", { "accordion--no-space": noSpace })}
    >
      {contents.map((item, i) => {
        let backgroundStyles: any = {
          background: item.backgroundColour || "#f5f5f5",
        }
        return (
          <AccordionItem key={i}>
            <AccordionItemHeading
              className="accordion-panel__title"
              aria-level={2}
            >
              <AccordionItemButton
                className="accordion-panel__button"
                style={backgroundStyles}
              >
                {item.title}
                {/* 
                  HACK: Padlock icon not updating properly. Making one a span and one a div to fix this.
                  We're not doing anything wrong AFAIK, this is some dumb react bug.
                */}
                {item.locked ? (
                  <span className="accordion-panel__trigger panel__trigger accordion-panel__trigger--locked"></span>
                ) : (
                  <div className="accordion-panel__trigger"></div>
                )}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {item.title === "Found it" && unlock}
              {item.locked ? (
                <div className="u-side-pad u-top-pad">
                  {/* 
                    TODO: supposed to be hut-specific. e.g.:
                    "Reveal ‘Regeneration’ by Mandy Hager by visiting the hut, finding its code and entering it above"
                  */}
                  {/* 
                    HACK: This is a div because when it is a p tag, the unlocked content tries to go in here
                    during dehydration, and has the effect of centering the first paragraph! We're
                    not doing anything wrong AFAIK, this is some dumb react bug.
                  */}
                  <div className="u-text-center u-mb">
                    Reveal by visiting the hut, finding its code and entering it
                    above
                  </div>
                </div>
              ) : (
                item.inner
              )}
            </AccordionItemPanel>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default Accordions
