import React, { useState } from "react"

type UnlockProps = {
  unlockCode: string
  isFound: boolean
  handleFound: Function
}

export default function Unlock({
  unlockCode,
  isFound,
  handleFound,
}: UnlockProps) {
  const id = `id${Math.random()
    .toString()
    .replace(/[^0-9]/gi, "")}`

  const [error, setError] = useState<string | null>(null)
  return isFound ? (
    <div className="unlock__success u-side-pad">
      Welcome! You can now access Hut Fiction below.
    </div>
  ) : (
    <form className="unlock" onSubmit={e => e.preventDefault()}>
      <label className="unlock__label" htmlFor={id}>
        Enter hut code
      </label>
      <input
        className="unlock__textbox"
        id={id}
        readOnly={isFound}
        aria-describedby={`${id}_error`}
        onChange={e => {
          const userCode = e.target.value
          if (isValid(userCode, unlockCode)) {
            setError(null)
            handleFound()
          } else {
            if (userCode.length) {
              setError(`Code "${userCode}" isn't correct`)
            } else {
              setError(null)
            }
          }
        }}
      />
      <div className="unlock__error" id={`${id}_error`} aria-live="polite">
        {error}
      </div>
    </form>
  )
}

export function normalizeCode(code: string): string {
  const l337 = {
    0: "o",
    1: "l",
    3: "e",
    4: "a",
    5: "s",
    7: "t",
    8: "b",
  }
  return code
    .trim()
    .replace(/[0-9]/g, match => {
      if (l337[match]) {
        return l337[match]
      }
      return match
    })
    .toLowerCase()
}

export function isValid(userCode: string, unlockCode: string): boolean {
  return normalizeCode(userCode) === normalizeCode(unlockCode)
}
