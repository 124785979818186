import React, { Fragment, useCallback } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { AccordionContent } from "../accordion/accordion"
import HutInfoSection from "../hutInfoSection/hutInfoSection"
import Accordions from "../accordion/accordion"
import Unlock from "../unlock/unlock"
import Footer from "../../components/footer/footer"

import { GeolocationContext, GeolocationContextType } from "../../AppState"

type WrapperProps = {
  hutId: string
  heading: string
  headingImg: string
  headingColour: string
  description: string
  accordionContent: AccordionContent[]
  unlockCode?: string
}

type Props = WrapperProps & {
  context: GeolocationContextType
}

const HutPageLayout = ({
  hutId,
  heading,
  headingImg,
  headingColour,
  description,
  accordionContent,
  unlockCode,
  context,
}: Props) => {
  const handleFound = useCallback(() => {
    context.addFoundHut(hutId)
    trackCustomEvent({
      category: "Hut page",
      action: `${hutId} unlocked`,
    })
  }, [])

  const hutFound = context.foundHuts.some(foundHutId => foundHutId === hutId)

  return (
    <Fragment>
      <HutInfoSection
        heading={heading}
        headingImg={headingImg}
        headingColour={headingColour}
        description={description}
      />
      {accordionContent && (
        <Accordions
          contents={accordionContent.map((content: AccordionContent) => {
            return {
              ...content,
              locked: content.locked ? !hutFound : false,
            }
          })}
          unlock={
            <Unlock
              handleFound={handleFound}
              isFound={hutFound}
              unlockCode={unlockCode}
            />
          }
        />
      )}
      <Footer />
    </Fragment>
  )
}

export default function HutPageLayoutWraper(props: WrapperProps) {
  return (
    <GeolocationContext.Consumer>
      {value => <HutPageLayout {...props} context={value} />}
    </GeolocationContext.Consumer>
  )
}
