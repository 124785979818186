import React from "react"
import appFooter from "../../assets/images/app-footer.svg"

const Footer = () => (
  <footer className="u-text-center u-side-pad u-top-pad u-bottom-pad">
    <a
      target="_blank"
      href="https://www.instagram.com/urbanhutclub/"
      rel="noopener noreferrer"
      aria-label="Urban Hut Club on Instagram"
    >
      <img className="width-full" src={appFooter} alt="" />
    </a>
  </footer>
)

export default Footer
