import React from "react"
import { navigate, Link } from "gatsby"
import ReactMapboxGl, {
  Layer,
  Feature,
  Marker,
  ZoomControl,
} from "react-mapbox-gl"
import mapboxgl from "mapbox-gl"
import { HutOverlayPolygon, Huts } from "../../AppState"
import backIcon from "../../assets/images/backicon.svg"

type HutMapProps = {
  foundHuts: Array<string>
  bounds: Array<Array<number>>
  height: string
  isHutPage?: boolean
}

let Map

let d = new Date()
let dateString =
  d.getFullYear() +
  ("0" + (d.getMonth() + 1)).slice(-2) +
  ("0" + d.getDate()).slice(-2)
let timeString =
  ("0" + d.getHours()).slice(-2) + "" + ("0" + d.getMinutes()).slice(-2)

const accessToken =
  "pk.eyJ1Ijoicm9iaW5tYXJzaGFsbDIiLCJhIjoiY2p5OWUzZjhiMDBiZzNocWl6YmN1ZTRoZyJ9.F4js7oy42GwE3FG7pT9TOg"

if (typeof window !== `undefined`) {
  Map = ReactMapboxGl({
    accessToken,
  })
}

const overlayPolygonPaint = {
  "fill-color": "#e0217c",
  "fill-opacity": 0.5,
}

const multiPolygonPaint = {
  "fill-color": ["get", "colour"], //"#0f786c",
  "fill-opacity": ["match", ["get", "found"], "true", 0.75, 0],
}

const HutMap = ({ foundHuts, bounds, height, isHutPage }: HutMapProps) => {
  if (!Map) {
    return <div />
  }

  return (
    <Map
      className="map"
      style="mapbox://styles/robinmarshall2/cjy9f3lsk0m011cn9a7wkad8y"
      containerStyle={{
        height: height,
        width: "100%",
      }}
      center={[174.95, -41.0]}
      zoom={[12]}
      fitBounds={bounds}
      onStyleLoad={map => {
        map.addControl(
          new mapboxgl.GeolocateControl({
            fitBoundsOptions: {
              maxZoom: 11,
            },
            positionOptions: {
              enableHighAccuracy: true,
            },
            trackUserLocation: true,
          }),
          "bottom-right"
        )
        map.on("mousedown", function(e) {
          console.info(
            JSON.stringify(e.point) + JSON.stringify(e.lngLat.wrap())
          )
        })
      }}
    >
      <ZoomControl />

      <Layer
        id="pinkWithHutCutOuts"
        minZoom={8}
        maxZoom={12}
        type="fill"
        paint={overlayPolygonPaint}
      >
        <Feature key="99" coordinates={HutOverlayPolygon} />
      </Layer>

      <Layer
        id="startHereIcons"
        minZoom={11}
        maxZoom={24}
        type="symbol"
        layout={{
          "icon-image": "start-here-pin",
          "icon-anchor": "bottom",
          "icon-allow-overlap": true,
        }}
      >
        {Huts.map((Hut, index) => {
          const hutId = "start-here-" + (index + 1)
          return (
            <Feature
              key={hutId}
              properties={{ title: Hut.description, offset: 100 }}
              coordinates={Hut.startCoords}
              onClick={() => {
                navigate(Hut.path)
              }}
            />
          )
        })}
      </Layer>

      <Layer
        minZoom={8}
        maxZoom={11}
        type="fill"
        paint={multiPolygonPaint}
        id="colouredHutIcons"
      >
        {Huts.map((Hut, index) => {
          const hutId = "hut" + (index + 1)

          if (!Hut || !Hut.coords) return null

          return (
            <Feature
              key={hutId}
              properties={{
                colour: Hut.colour,
                found: foundHuts.includes(hutId) ? "true" : false,
              }}
              coordinates={[Hut.coords]}
              onClick={() => {
                navigate(Hut.path)
              }}
            />
          )
        })}
      </Layer>

      <Layer
        id="hutLabels"
        minZoom={8}
        maxZoom={24}
        type="symbol"
        layout={{
          "text-field": ["get", "title"],
          "text-font": ["Poppins SemiBold", "Arial Unicode MS Bold"],
          "text-offset": ["get", "offset"],
          "text-anchor": "left",
          "text-allow-overlap": true,
          "text-transform": "uppercase",
          "text-letter-spacing": 0.1,
          "text-max-width": 15,
        }}
        paint={{
          "text-color": "#ffffff",
        }}
      >
        {Huts.map((Hut, index) => {
          const hutId = "label-" + (index + 1)
          return (
            <Feature
              key={hutId}
              properties={{
                title: Hut.description,
                offset: [2, Hut.verticalOffset],
              }}
              coordinates={Hut.startCoords}
              onClick={() => {
                navigate(Hut.path)
              }}
            />
          )
        })}
      </Layer>

      {isHutPage && (
        <Link to="/map" className="back-to-map">
          <img src={backIcon} role="presentation" />
          <span className="u-accessible-text">Back to map page</span>
        </Link>
      )}
    </Map>
  )
}

export default HutMap
