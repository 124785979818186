import React from "react"
import SEO from "../seo"
import AppState from "../../AppState"

type Props = {
  children: React.ReactNode
  title: string
  fullWidth: boolean
}

const Layout = ({ children, title, fullWidth }: Props) => {
  return (
    <AppState>
      <SEO title={title} />
      <div className={(fullWidth?'full-width':'layout')}>
        <main>{children}</main>
      </div>
    </AppState>
  )
}

export default Layout
