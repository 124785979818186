import React, { useState, Fragment } from "react"

type AudioProps = {
  audio?: string
}

const Audio = ({ audio }: AudioProps) => {
  const [audioInitialised, setAudioInitialised] = useState(false)

  return (
    <Fragment>
      {audio && (
        <div className="audio-container">
          {audioInitialised ? (
            <audio controls autoPlay>
              <source src={audio} type="audio/mpeg" />
            </audio>
          ) : (
            <button
              className="audio-button"
              type="button"
              onClick={() => setAudioInitialised(true)}
            >
              <span className="audio-button__text">Audio version</span>
            </button>
          )}
        </div>
      )}
    </Fragment>
  )
}

export default Audio
